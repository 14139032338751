import React from 'react'
import Layout from '../layouts/product'

import { Header } from 'semantic-ui-react'

import ContactForm from '../components/contact'

const ContactUs = () => (
  <Layout>
    
    <div style={{marginTop: '120px'}}>
    <Header as='h1'>Contact Us</Header>
      Thanks for taking the time to contact us, we are happy to help with custom
      or large orders, if you've had any problem with your order or if you'd like
      to suggest a design for us to add. Please use the form below and we will
      get back to you at the email address you provide as soon as we can.
      </div>
    <ContactForm />
    
  </Layout>
)

export default ContactUs
