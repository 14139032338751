import React from 'react'
import { Container, Form, Segment, Button } from 'semantic-ui-react'
import config from '../config'


// contact form from homesite, does it work before changes for semantic-ui
const ContactForm = props => (
  <div>
    
    <Form
      name="Contact"
      method="POST"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      
      action="/thanks"
    >
      <input type="hidden" name="form-name" value="Contact" />

      <Segment>
      
        <label htmlFor="name">Name</label>
        <input type="text" name="name" id="name" />

        <label htmlFor="email">Email</label>
        <input type="text" name="email" id="email" />

        <label htmlFor="message">Message</label>
        <textarea name="message" id="message" rows="6" />
        
        <Container style={{padding: '15px 0 15px 0'}} textAlign="right">
        <Button inverted style={{backgroundColor: config.colors.primary}} type="reset" value="Clear">
            Clear
          </Button>
          <Button
            style={{backgroundColor: config.colors.primary, color: 'white'}}
            type="submit"
            value="Send Message"
            className="special"
          >
            Send Message
          </Button>

          
        </Container>
      </Segment>
    </Form>
  </div>
)

export default ContactForm
